<template>
  <div id="printable" :class="$style.line">
    <div v-for="(bar, index) in filteredBarData" :id="index" :key="bar.printer" :class="$style.container">
      <div :class="$style.legend">
        {{ bar.printer }}
      </div>
      <div :class="$style.chartContainer">
        <svg :class="$style.chart">
          <g>
            <bar :class="$style.bar" :length="bar.pagesNumber" :max="maxLength" :rectStyle="pagesRectStyle"
                 :textStyle="pagesTextStyle" :y="20" title="Страниц"
            />
            <bar :class="$style.bar" transform="translate(0,34)" :length="bar.docsNumber" :max="maxLength"
                 :rectStyle="docsRectStyle" :textStyle="docsTextStyle" :y="20" title="Документов"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import bar from '@/components/common/bars/bar.vue';
import { getPrinterStatistics } from '@/api/methods/reports/printers/statistics/index';

export default {
  components: {
    bar,
  },
  data: () => ({
    name: 'Статистика печати по принтерам',
    barData: [],
    pagesRectStyle: {
      height: '34px',
      fill: '#94C2E0',
    },
    pagesTextStyle: {
      fill: '#1970A7',
      fontSize: '12px',
      letterSpacing: '-0.24px',
    },
    docsRectStyle: {
      height: '34px',
      fill: '#FFECBC',
    },
    docsTextStyle: {
      fill: '$yellow',
      fontSize: '12px',
      letterSpacing: '-0.24px',
    },
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    maxLength() {
      const maxlength = Math.max(...this.barData.map((o) => o.pagesNumber));
      return maxlength;
    },
    sortState() {
      return this.$store.getters['pageSpecificData/printStatSortState'];
    },
    filteredBarData() {
      const selectedOption = this.$store.getters['filters/printerSelectedOption'];
      const sortedData = this.barData.slice(0).sort(this.storeBasedSort);
      if (selectedOption) {
        return sortedData.filter((barInfo) => barInfo.printer.includes(selectedOption));
      }
      return sortedData;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.fetch();
      }
    },
  },
  created() {
    this.$store.commit('exportData/setPrintStat', { name: this.name, data: [] });
    this.fetch();
  },
  methods: {
    storeBasedSort(a, b) {
      if (this.sortState) return b.pagesNumber - a.pagesNumber;
      return a.pagesNumber - b.pagesNumber;
    },
    barLength(pages) {
      const percent = (pages * 100) / this.maxLength;
      return percent;
    },
    fetch() {
      this.barData = [];
      if (this.dateRange.dateFrom && this.dateRange.dateTo) {
        this.promise = getPrinterStatistics(
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
        ).then(({ data }) => {
          this.barData = data;
          this.sendSelectOptions();
          return { data };
        });
      }
    },
    sendSelectOptions() {
      const printerNameList = this.barData.map((x) => x.printer);

      this.$store.commit(
        'filters/setPrinterOptions',
        /* eslint-disable-next-line */
        [].concat.apply([], printerNameList)
      );
    },
  },
};
</script>

<style lang="scss" module>
.chart {
  height: 68px;
  width: 100%;
}

.container {
  width: 100%;
  display: flex;
}

.chartContainer {
  flex-grow: 1;
  overflow: auto;
}

.legend {
  background-color: $light-gray;
  height: 68px;
  width: 151px;
  align-items: center;
  margin-bottom: 28px;
  margin-left: 20px;
  font-size: 13px;
  letter-spacing: -0.24px;
  font-weight: 400;
  cursor: pointer;
  padding: 20px 25px;
  display: flex;
}

.line {
  justify-content: space-between;
  height: 100%;
  margin: 20px 8px 20px 0;
  overflow: auto;
  padding-right: 12px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
