import axios from '@/api/axios';

export function getPrintDocumentStatistics(dateFrom, dateTo) {
  const result = axios.get(`/reports/printers/statistics/documents?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data: { groupedByDates, groupedByPrinters } }) => ({ groupedByDates, groupedByPrinters }));
  return result;
}

export function getPrinterStatistics(dateFrom, dateTo) {
  const result = axios.get(`/reports/printers/statistics/printers?dateFrom=${dateFrom}&dateTo=${dateTo}`);
  return result;
}

export function getPrintUserStatistics(dateFrom, dateTo) {
  const result = axios.get(`/reports/printers/statistics/users?dateFrom=${dateFrom}&dateTo=${dateTo}`);
  return result;
}
